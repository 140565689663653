import Vue from "vue";
import VueRouter from "vue-router";
import VueNoty from "vuejs-noty";
import { Auth } from "aws-amplify";
import moment from "moment";
import App from "./App";
import i18n from "./i18n";
import "./vee-validate";

// Plugins
import Chartist from "chartist";
import DashboardPlugin from "./material-dashboard";

import AuthLayout from "./components/pages/auth/AuthLayout";
import Login from "./components/pages/auth/Login.vue";
import Register from "./components/pages/auth/Register.vue";
import RememberPassword from "./components/pages/auth/RememberPassword.vue";
import ResetPassword from "./components/pages/auth/ResetPassword.vue";
import DashboardLayout from "./components/pages/Layout/DashboardLayout";
import Dashboard from "./components/pages/Dashboard";
import LogisticsCenterList from "./components/pages/LogisticsCenters/List";
import LogisticsCenterForm from "./components/pages/LogisticsCenters/Form";
import ProviderList from "./components/pages/Providers/List";
import ProviderForm from "./components/pages/Providers/Form";
import ServicesList from "./components/pages/Services/List";
import ServiceDetail from "./components/pages/Services/Detail";
import Synchronization from "./components/pages/Synchronization/Sync";
import UserList from "./components/pages/Users/List";
import UserForm from "./components/pages/Users/Form";
import UserDetail from "./components/pages/Users/Detail";
import VehiclesList from "./components/pages/Vehicles/List";
import VehiclesForm from "./components/pages/Vehicles/Form";
import Profile from "./components/pages/UserProfile";
import LegalText from "./components/pages/Legal/LegalText";

import { SUPPORTED_LOCALES } from "./config";
import "./assets/css/tailwind.css";
import "./assets/css/general.css";

Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.config.productionTip = false;
Vue.prototype.moment = moment;
moment.locale(i18n.locale);

Vue.use(VueNoty, {
    progressBar: false,
    layout: "center", //top, topLeft, topCenter, topRight, center, centerLeft, centerRight, bottom, bottomLeft, bottomCenter, bottomRight
    theme: "bootstrap-v4",
    timeout: 3000, //Set 'false' for sticky notifications
});

// Date formatters
Vue.filter("formatDate", function (value) {
    if (value) {
        return moment.utc(String(value)).local().format("DD/MM/YYYY HH:mm");
    }
});

Vue.filter("formatDateNoTime", function (value, format) {
    if (value) {
        if (format) {
            return moment(String(value), format).format("DD/MM/YYYY");
        } else {
            return moment(String(value)).format("DD/MM/YYYY");
        }
    }
});
// vue-cookies
Vue.use(require('vue-cookies'));

function getLocaleRoutes(locale = "es") {
    let publicRoutes = {
        path: "/" + locale + "/legal",
        name: locale + ".legal",
        component: LegalText,
    };

    let authRoutes = {
        path: "/" + locale,
        component: AuthLayout,
        children: [
            {
                path: i18n.t("routes.login.nav", locale),
                name: locale + ".login",
                component: Login,
                meta: { requiresGuest: true },
            },
            {
                path: i18n.t("routes.register.nav", locale),
                name: locale + ".register",
                component: Register,
                meta: { requiresGuest: true },
            },
            {
                path: i18n.t("routes.remember_password.nav", locale),
                name: locale + ".remember_password",
                component: RememberPassword,
                meta: { requiresGuest: true },
            },
            {
                path: i18n.t("routes.reset_password.nav", locale),
                name: locale + ".reset-password",
                component: ResetPassword,
                meta: { requiresGuest: true },
            },
        ],
    };

    var routes = [
        {
            path: "/" + locale,
            component: DashboardLayout,
            children: [
                {
                    path: i18n.t("routes.dashboard.nav", locale),
                    name: locale + ".dashboard",
                    component: Dashboard,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.profile.nav", locale),
                    name: locale + ".profile",
                    component: Profile,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.drivers.nav", locale),
                    name: locale + ".drivers",
                    component: UserList,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.driver_create.nav", locale),
                    name: locale + ".driver_create",
                    component: UserForm,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.driver_detail.nav", locale),
                    name: locale + ".driver_detail",
                    component: UserDetail,
                    props: true,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.logistics_centers.nav", locale),
                    name: locale + ".logistics_centers",
                    component: LogisticsCenterList,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.logistics_center_form.nav", locale),
                    name: locale + ".logistics_center_form",
                    component: LogisticsCenterForm,
                    props: true,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.providers.nav", locale),
                    name: locale + ".providers",
                    component: ProviderList,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.provider_form.nav", locale),
                    name: locale + ".provider_form",
                    component: ProviderForm,
                    props: true,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.services.nav", locale),
                    name: locale + ".services",
                    component: ServicesList,
                    meta: { requiresAuth: true },
                },
                {
                    path:
                        i18n.t("routes.service_detail.nav", locale) +
                        "/:idService",
                    name: locale + ".service_detail",
                    component: ServiceDetail,
                    props: true,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.synchronization.nav", locale),
                    name: locale + ".synchronization",
                    component: Synchronization,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.users.nav", locale),
                    name: locale + ".users",
                    component: UserList,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.user_create.nav", locale),
                    name: locale + ".user_create",
                    component: UserForm,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.user_detail.nav", locale),
                    name: locale + ".user_detail",
                    component: UserDetail,
                    props: true,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.vehicles.nav", locale),
                    name: locale + ".vehicles",
                    component: VehiclesList,
                    meta: { requiresAuth: true },
                },
                {
                    path: i18n.t("routes.vehicle_form.nav", locale),
                    name: locale + ".vehicle_form",
                    component: VehiclesForm,
                    props: true,
                    meta: { requiresAuth: true },
                },
            ],
        },
        authRoutes,
        publicRoutes,
    ];

    return routes;
}

function getRoutes() {
    var routes = [
        {
            path: "/",
            name: "home",
            redirect: { name: i18n.locale + ".dashboard" },
        },
        //{ path: '/protected', component: Protected, meta: { requiresAuth: true } },
    ];

    SUPPORTED_LOCALES.forEach((locale) => {
        var localeRoutes = getLocaleRoutes(locale.code);
        routes = routes.concat(localeRoutes);
    });

    return routes;
}

const router = new VueRouter({
    mode: "history",
    // base: window.location.origin,
    linkActiveClass: "nav-item active",
    linkExactActiveClass: "nav-item active",
    routes: getRoutes(),
    scrollBehavior: function (to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: "smooth",
            };
        }
    },
});

router.beforeResolve((to, from, next) => {
    var locale = to.matched[0]["path"].slice(1);
    if (SUPPORTED_LOCALES.find((loc) => loc.code === locale)) {
        i18n.locale = locale;
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        Auth.currentAuthenticatedUser()
            .then(() => {
                next();
            })
            .catch(() => {
                console.error("Unauthorized");
                next({
                    name: locale + ".login",
                });
            });
    }

    if (to.matched.some((record) => record.meta.requiresGuest)) {
        Auth.currentAuthenticatedUser()
            .then(() => {
                console.error("Already connected");
                this.$noty.warn(this.$t("error.already_connected"));
                next({
                    name: locale + ".dashboard",
                });
            })
            .catch(() => {
                next();
            });
    }

    next();
});

// global library setup
Object.defineProperty(Vue.prototype, "$Chartist", {
    get() {
        return this.$root.Chartist;
    },
});

new Vue({
    render: (h) => h(App),
    i18n,
    router,
    data: {
        Chartist: Chartist,
    },
}).$mount("#app");
